import React, { useState, useEffect } from "react";
import {
    Typography,
    Container,
    Box,
    TextField,
    Button,
    useTheme,
    Fade,
    Grow,
    Paper,
    Snackbar,
    useMediaQuery,
} from "@mui/material";
import { motion, useAnimation } from "framer-motion";
import { Send } from "lucide-react";
import Header from "./subcomponents/common/Header";
import Footer from './subcomponents/common/Footer';
import useLogging from "../hooks/useLogging";
import { submitContactForm } from "../services/api";

function AboutUsPage() {
    const theme = useTheme();
    const logger = useLogging("AboutUsPage");
    const [formData, setFormData] = useState({ name: "", email: "", message: "" });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const controls = useAnimation();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        controls.start(i => ({
            opacity: 1,
            y: 0,
            transition: { delay: i * 0.2 }
        }));
    }, [controls]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            await submitContactForm(formData);
            setSnackbarMessage("Thank you for your message. We'll get back to you soon!");
            setSnackbarOpen(true);
            setFormData({ name: "", email: "", message: "" });
        } catch (error) {
            logger.error("Error submitting contact form:", error);
            setSnackbarMessage("Failed to submit the form. Please try again.");
            setSnackbarOpen(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Box
            sx={{
                minHeight: "100vh",
                bgcolor: "background.default",
                color: "text.primary",
                overflow: "hidden",
                position: "relative",
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {/* Dynamic Background */}
            <Box
                sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    overflow: "hidden",
                    zIndex: 0,
                }}
            >
                <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
                            <stop offset="0%" stopColor={theme.palette.primary.dark} stopOpacity="0.2" />
                            <stop offset="100%" stopColor={theme.palette.secondary.dark} stopOpacity="0.2" />
                        </linearGradient>
                    </defs>
                    <rect width="100%" height="100%" fill="url(#grad1)" />
                    <motion.circle
                        cx="10%"
                        cy="10%"
                        r="5%"
                        fill={theme.palette.primary.main}
                        opacity="0.1"
                        animate={{
                            scale: [1, 1.2, 1],
                            x: ["0%", "5%", "0%"],
                            y: ["0%", "5%", "0%"],
                        }}
                        transition={{
                            duration: 10,
                            repeat: Infinity,
                            repeatType: "reverse",
                        }}
                    />
                    <motion.circle
                        cx="90%"
                        cy="90%"
                        r="7%"
                        fill={theme.palette.secondary.main}
                        opacity="0.1"
                        animate={{
                            scale: [1, 1.3, 1],
                            x: ["0%", "-5%", "0%"],
                            y: ["0%", "-5%", "0%"],
                        }}
                        transition={{
                            duration: 12,
                            repeat: Infinity,
                            repeatType: "reverse",
                        }}
                    />
                </svg>
            </Box>

            <Header />
            <Container
                maxWidth="lg"
                sx={{
                    pt: 8,
                    pb: 8,
                    position: "relative",
                    zIndex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: 'calc(100vh - 64px)', // Adjust based on your header height
                }}
            >
                <Grow in={true} timeout={1000}>
                    <Typography
                        variant="h1"
                        component="h1"
                        sx={{
                            fontSize: { xs: "2.5rem", sm: "3.5rem", md: "4.5rem" },
                            fontWeight: "bold",
                            mb: 6,
                            textAlign: "center",
                            background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                            textShadow: "0 0 20px rgba(100, 255, 218, 0.2)",
                        }}
                    >
                        Revolutionizing Hiring
                    </Typography>
                </Grow>

                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.5 }}
                >
                    <Typography
                        variant="h6"
                        align="center"
                        sx={{
                            mb: 8,
                            maxWidth: "900px",
                            mx: "auto",
                            textAlign: "center",
                            color: theme.palette.text.secondary,
                            textShadow: "0 0 10px rgba(255, 255, 255, 0.1)",
                        }}
                    >
                        At Dobr.AI, we're passionate about building the future of hiring.                     </Typography>
                </motion.div>

                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 1 }}
                    style={{ width: '100%', maxWidth: '900px' }}
                >
                    <Paper
                        elevation={3}
                        sx={{
                            p: { xs: 2, sm: 4 },
                            backgroundColor: "rgba(26, 32, 44, 0.7)",
                            backdropFilter: "blur(10px)",
                            borderRadius: 2,
                            boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
                            border: "1px solid rgba(255, 255, 255, 0.18)",
                            position: "relative",
                            overflow: "hidden",
                            width: '100%',
                        }}
                    >
                        <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
                            Get in Touch
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <TextField
                                fullWidth
                                label="Name"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                required
                                margin="normal"
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: "rgba(255, 255, 255, 0.23)",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: "rgba(255, 255, 255, 0.5)",
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: theme.palette.primary.main,
                                        },
                                    },
                                }}
                            />
                            <TextField
                                fullWidth
                                label="Email"
                                name="email"
                                type="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                                margin="normal"
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: "rgba(255, 255, 255, 0.23)",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: "rgba(255, 255, 255, 0.5)",
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: theme.palette.primary.main,
                                        },
                                    },
                                }}
                            />
                            <TextField
                                fullWidth
                                label="Message"
                                name="message"
                                multiline
                                rows={4}
                                value={formData.message}
                                onChange={handleInputChange}
                                required
                                margin="normal"
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: "rgba(255, 255, 255, 0.23)",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: "rgba(255, 255, 255, 0.5)",
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: theme.palette.primary.main,
                                        },
                                    },
                                }}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={isSubmitting}
                                sx={{
                                    mt: 2,
                                    background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.25)",
                                    "&:hover": {
                                        background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.secondary.dark})`,
                                    },
                                }}
                            >
                                {isSubmitting ? "Sending..." : "Send Message"}
                            </Button>
                        </form>

                        <Box
                            component={motion.div}
                            animate={{
                                scale: [1, 1.2, 1],
                                rotate: [0, 90, 0],
                            }}
                            transition={{
                                duration: 20,
                                ease: "linear",
                                repeat: Infinity,
                            }}
                            sx={{
                                position: "absolute",
                                top: -100,
                                right: -100,
                                width: 300,
                                height: 300,
                                borderRadius: "50%",
                                background: `linear-gradient(45deg, ${theme.palette.primary.main}20, ${theme.palette.secondary.main}20)`,
                                filter: "blur(40px)",
                                zIndex: -1,
                            }}
                        />
                    </Paper>
                </motion.div>
            </Container>
            <Footer />
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
            />
        </Box>
    );
}

export default AboutUsPage;