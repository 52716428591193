import React from 'react';
import { Typography, Box, useTheme } from '@mui/material';

const Step = ({ number, title, description }) => {
  const theme = useTheme();
  return (
    <Box sx={{ display: "flex", alignItems: "flex-start", mb: 4 }}>
      <Box
        sx={{
          border: `2px solid ${theme.palette.primary.main}`,
          borderRadius: "50%",
          width: 40,
          height: 40,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "bold",
          mr: 2,
          flexShrink: 0,
          color: theme.palette.primary.main,
        }}
      >
        {number}
      </Box>
      <Box>
        <Typography
          variant="h6"
          component="h3"
          sx={{ fontWeight: "bold", mb: 1 }}
        >
          {title}
        </Typography>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default Step;