import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  useTheme,
} from "@mui/material";
import useLogging from "../hooks/useLogging";
import Header from "./subcomponents/common/Header";
import { useAuth } from "../contexts/AuthContext";
import Footer from './subcomponents/common/Footer';
import AnimatedBackground from './subcomponents/landingpage/AnimatedBackground';
import HeroSection from './subcomponents/landingpage/HeroSection';
import FeaturesSection from './subcomponents/landingpage/FeaturesSection';
import HowItWorksSection from './subcomponents/landingpage/HowItWorksSection';
import CallToAction from './subcomponents/landingpage/CallToAction';
import ScrollToTopButton from './subcomponents/landingpage/ScrollToTopButton';

function LandingPage() {
  const logger = useLogging("LandingPage");
  const [showScrollTop, setShowScrollTop] = useState(false);
  const theme = useTheme();
  const { signup } = useAuth();

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 300);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleStart = () => {
    signup();
  };

  const scrollToMore = () => {
    const moreInfoSection = document.getElementById("more-info");
    if (moreInfoSection) {
      moreInfoSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        bgcolor: "background.default",
        color: "text.primary",
        position: "relative",
        overflow: "hidden",
        fontFamily: "Roboto Mono, monospace",
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <AnimatedBackground />
      
      <Box sx={{ position: "relative", zIndex: 1, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Header />

        <HeroSection handleStart={handleStart} scrollToMore={scrollToMore} />

        <Box id="more-info" sx={{ py: 20, bgcolor: "background.paper", flexGrow: 1 }}>
          <Container>
            <FeaturesSection />
            <HowItWorksSection />
            <CallToAction handleStart={handleStart} />
          </Container>
        </Box>

        <ScrollToTopButton showScrollTop={showScrollTop} scrollToTop={scrollToTop} />
      </Box>

      <Footer />
    </Box>
  );
}

export default LandingPage;
