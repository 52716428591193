import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Box,
    Container,
    Typography,
    useTheme,
    Button,
    Fade,
} from "@mui/material";
import useLogging from "../hooks/useLogging";
import Header from "./subcomponents/common/Header";
import { ChevronLeft } from "lucide-react";

function InviteOnlyPage() {
    const location = useLocation();
    const logger = useLogging("InviteOnlyPage");
    const theme = useTheme();
    const navigate = useNavigate();
    const email = location.state?.email || "your email";

    logger.log("Rendered InviteOnlyPage for email:", email);

    const handleBackToHome = () => {
        logger.log("User navigated back to home");
        navigate("/");
    };

    return (
        <Box
            sx={{
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
                bgcolor: "background.default",
                color: "text.primary",
            }}
        >
            <Header />

            <Container maxWidth="sm" sx={{ mt: 24, mb: 4 }}>
                <Typography
                    variant="h2"
                    component="h1"
                    sx={{
                        mb: 6,
                        textAlign: "center",
                        fontWeight: "bold",
                        background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                    }}
                >
                    You're on the waiting list!
                </Typography>
                <Typography variant="h5" sx={{ mb: 4, textAlign: "center" }}>
                    We're currently in invite-only mode. Don't worry, the party's just getting started.
                </Typography>
                <Typography variant="body1" sx={{ textAlign: "center" }}>
                    We're excited about the interest in Dobr.ai. Keep an eye on your inbox for your exclusive invite.
                </Typography>

                <Fade in={true} timeout={1200}>
                    <Box sx={{ textAlign: "center", mt: 16 }}>
                        <Button
                            variant="contained"
                            onClick={handleBackToHome}
                            startIcon={<ChevronLeft />}
                            sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.contrastText,
                                "&:hover": {
                                    backgroundColor: theme.palette.primary.dark,
                                },
                            }}
                        >
                            Back to Home
                        </Button>
                    </Box>
                </Fade>
            </Container>

        </Box>
    );
}

export default InviteOnlyPage;