const useLogging = (componentName) => {
  const isDevelopment = process.env.REACT_APP_ENV === 'development';

  const log = (message, ...args) => {
    if (isDevelopment) {
      console.log(`[${componentName}]`, message, ...args);
    }
  };

  const error = (message, ...args) => {
    if (isDevelopment) {
      console.error(`[${componentName}]`, message, ...args);
    }
  };

  const warn = (message, ...args) => {
    if (isDevelopment) {
      console.warn(`[${componentName}]`, message, ...args);
    }
  };

  return { log, error, warn };
};

export default useLogging;
