import React from 'react';
import { Typography, Box, useTheme } from '@mui/material';
import FeatureCard from './FeatureCard';

const FeaturesSection = () => {
  const theme = useTheme();

  return (
    <Box id="features" sx={{ mb: 20 }}>
      <Typography
        variant="h2"
        component="h2"
        sx={{
          fontSize: "3rem",
          fontWeight: "bold",
          mb: 8,
          textAlign: "center",
          letterSpacing: "-0.03em",
        }}
      >
        Features
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: { xs: 6, md: 8 },
          maxWidth: "100%",
          margin: "0 auto",
          padding: { xs: 2, sm: 3, md: 4 },
        }}
      >
       <FeatureCard
                  title="Realistic Interviews"
                  description="Code in a real-time environment that mimics actual interview conditions."
                  svgContent={
                    <g>
                      <circle
                        cx="85"
                        cy="85"
                        r="5"
                        fill={theme.palette.primary.main}
                        fillOpacity="0.7"
                      />
                      <circle
                        cx="70"
                        cy="85"
                        r="5"
                        fill={theme.palette.primary.main}
                        fillOpacity="0.6"
                      />
                      <path
                        d="M70,85 L85,85"
                        stroke={theme.palette.primary.light}
                        strokeWidth="1"
                      />
                      <circle
                        cx="55"
                        cy="85"
                        r="0"
                        fill={theme.palette.primary.main}
                        fillOpacity="0"
                      />
                      <circle
                        cx="70"
                        cy="70"
                        r="0"
                        fill={theme.palette.primary.main}
                        fillOpacity="0"
                      />
                      <path
                        d="M55,85 L70,85 M70,70 L70,85"
                        stroke={theme.palette.primary.light}
                        strokeWidth="0"
                      />
                    </g>
                  }
                />
                <FeatureCard
                  title="Extensive Problem Set"
                  description="Access a vast library of coding problems across various difficulty levels. From algorithms to system design, we've got you covered."
                  svgContent={
                    <g>
                      <circle
                        cx="80"
                        cy="80"
                        r="15"
                        fill={theme.palette.secondary.main}
                        fillOpacity="0.6"
                      />
                      <circle
                        cx="50"
                        cy="50"
                        r="0"
                        fill={theme.palette.secondary.main}
                        fillOpacity="0"
                      />
                      <circle
                        cx="80"
                        cy="50"
                        r="0"
                        fill={theme.palette.secondary.main}
                        fillOpacity="0"
                      />
                      <circle
                        cx="50"
                        cy="80"
                        r="0"
                        fill={theme.palette.secondary.main}
                        fillOpacity="0"
                      />
                      <path
                        d="M50,50 L80,50 L80,80 L50,80 Z"
                        stroke={theme.palette.secondary.light}
                        strokeDasharray="5,5"
                        strokeWidth="0"
                        fill="none"
                      />
                    </g>
                  }
                />
                <FeatureCard
                  title="AI Powered"
                  description="Interact with our advanced AI interviewer for a realistic interview experience. Improve your communication and problem-solving skills."
                  svgContent={
                    <g>
                      <circle
                        cx="85"
                        cy="75"
                        r="4"
                        fill={theme.palette.primary.main}
                        fillOpacity="0.8"
                      />
                      <circle
                        cx="85"
                        cy="90"
                        r="4"
                        fill={theme.palette.primary.main}
                        fillOpacity="0.7"
                      />
                      <circle
                        cx="70"
                        cy="82.5"
                        r="0"
                        fill={theme.palette.primary.main}
                        fillOpacity="0"
                      />
                      <circle
                        cx="55"
                        cy="75"
                        r="0"
                        fill={theme.palette.primary.main}
                        fillOpacity="0"
                      />
                      <circle
                        cx="55"
                        cy="90"
                        r="0"
                        fill={theme.palette.primary.main}
                        fillOpacity="0"
                      />
                      <path
                        d="M85,75 L70,82.5 M85,90 L70,82.5 M70,82.5 L55,75 M70,82.5 L55,90"
                        stroke={theme.palette.primary.light}
                        strokeWidth="0"
                      />
                    </g>
                  }
                />
                <FeatureCard
                  title="Detailed Feedback"
                  description="Receive comprehensive feedback on your interview performance. Identify strengths and areas for improvement to enhance your skills."
                  svgContent={
                    <g>
                      <rect
                        x="75"
                        y="85"
                        width="20"
                        height="10"
                        fill={theme.palette.error.main}
                        fillOpacity="0.7"
                        rx="2"
                      />
                      <rect
                        x="75"
                        y="67"
                        width="0"
                        height="15"
                        fill={theme.palette.error.main}
                        fillOpacity="0"
                        rx="2"
                      />
                      <rect
                        x="75"
                        y="52"
                        width="0"
                        height="12"
                        fill={theme.palette.error.main}
                        fillOpacity="0"
                        rx="2"
                      />
                      <rect
                        x="52"
                        y="88"
                        width="0"
                        height="7"
                        fill={theme.palette.error.main}
                        fillOpacity="0"
                        rx="2"
                      />
                      <rect
                        x="52"
                        y="72"
                        width="0"
                        height="13"
                        fill={theme.palette.error.main}
                        fillOpacity="0"
                        rx="2"
                      />
                      <rect
                        x="52"
                        y="52"
                        width="0"
                        height="17"
                        fill={theme.palette.error.main}
                        fillOpacity="0"
                        rx="2"
                      />
                    </g>
                  }
                />
      </Box>
    </Box>
  );
};

export default FeaturesSection;