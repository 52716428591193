import React from 'react';
import { Typography, Box } from '@mui/material';
import Step from './Step';

const HowItWorksSection = () => {
  return (
    <Box id="how-it-works" sx={{ mb: 20 }}>
      <Typography
        variant="h2"
        component="h2"
        sx={{
          fontSize: "3rem",
          fontWeight: "bold",
          mb: 8,
          textAlign: "center",
          letterSpacing: "-0.03em",
        }}
      >
        How It Works
      </Typography>
      <Box sx={{ maxWidth: "800px", margin: "0 auto" }}>
        <Step
          number={1}
          title="Choose Your Focus"
          description="Select the topics you want to practice and the level of difficulty."
        />
        <Step
          number={2}
          title="Start Mock Interview"
          description="Begin your AI-powered coding interview session."
        />
        <Step
          number={3}
          title="Receive Feedback"
          description="Get instant feedback on your performance and areas for improvement."
        />
      </Box>
    </Box>
  );
};

export default HowItWorksSection;