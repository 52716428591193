import React, { useMemo } from 'react';
import { AppBar, Toolbar, Typography, Button, Container, Box, useTheme, alpha } from "@mui/material";
import { Clock } from "lucide-react";

const StatusArea = React.memo(({ timeRemaining, handleEndInterview, currentStatus, isTimeExpired }) => {
    const theme = useTheme();

    const formattedTime = useMemo(() => {
        const minutes = Math.floor(timeRemaining / 60);
        const seconds = timeRemaining % 60;
        return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    }, [timeRemaining]);

    return (
        <>
            <AppBar position="static" color="transparent" elevation={0} sx={{ borderBottom: `1px solid ${theme.palette.divider}`, backdropFilter: "blur(8px)", bgcolor: "rgba(26, 26, 26, 0.8)" }}>
                <Container maxWidth={false} sx={{ px: { xs: 2, sm: 3, md: 4 } }}>
                    <Toolbar disableGutters sx={{ justifyContent: "space-between", py: 2 }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box
                                sx={{
                                    width: 40,
                                    height: 40,
                                    borderRadius: "50%",
                                    border: `2px solid ${theme.palette.primary.main}`,
                                    mr: 2,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    animation: "pulse 2s infinite",
                                    "@keyframes pulse": {
                                        "0%": {
                                            boxShadow: "0 0 0 0 rgba(100, 255, 218, 0.4)",
                                        },
                                        "70%": {
                                            boxShadow: "0 0 0 10px rgba(100, 255, 218, 0)",
                                        },
                                        "100%": {
                                            boxShadow: "0 0 0 0 rgba(100, 255, 218, 0)",
                                        },
                                    },
                                }}
                            ></Box>
                            <Typography variant="h6" component="div" sx={{ fontWeight: "bold", letterSpacing: 1, background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }}>
                                Dobr.AI
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                            <Box sx={{ display: "flex", alignItems: "center", padding: "4px 12px", borderRadius: "16px", bgcolor: "rgba(100, 255, 218, 0.1)" }}>
                                <Clock size={20} color={theme.palette.primary.main} />
                                <Typography variant="h6" component="div" sx={{ fontWeight: "bold", letterSpacing: 1, ml: 1, color: theme.palette.primary.main }}>
                                    {formattedTime}
                                </Typography>
                            </Box>
                        </Box>
                        <Button 
                            color="primary"
                            variant="outlined"
                            onClick={handleEndInterview}
                            sx={{
                                borderRadius: "4px",
                                textTransform: "none",
                                fontWeight: "bold",
                                px: 3,
                                py: 1,
                                borderColor: theme.palette.error.light,
                                color: theme.palette.error.light,
                                "&:hover": {
                                    bgcolor: alpha(theme.palette.error.main, 0.2),
                                    borderColor: theme.palette.error.main,
                                    color: theme.palette.error.main,
                                },
                                "&:disabled": {
                                    opacity: 0.5,
                                    cursor: "not-allowed",
                                }
                            }}
                        >
                            Submit Solution
                        </Button>
                    </Toolbar>
                </Container>
            </AppBar>
            {currentStatus === "ended" && (
                <Box
                    sx={{
                        width: '100%',
                        bgcolor: alpha(theme.palette.error.light, 0.1),
                        py: 0.5,
                        borderBottom: `1px solid ${alpha(theme.palette.error.main, 0.2)}`,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant="caption"
                        sx={{
                            color: theme.palette.error.main,
                            fontWeight: 500,
                            letterSpacing: 0.5,
                        }}
                    >
                        The interview has ended.
                    </Typography>
                </Box>
            )}
        </>
    );
}, (prevProps, nextProps) => {
    return prevProps.timeRemaining === nextProps.timeRemaining &&
           prevProps.currentStatus === nextProps.currentStatus &&
           prevProps.isTimeExpired === nextProps.isTimeExpired;
});

export default StatusArea;
