import React, { useState } from 'react';
import { Typography, Box, useTheme } from '@mui/material';

const FeatureCard = ({ title, description, svgContent }) => {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();

  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        position: "relative",
        overflow: "hidden",
        borderRadius: "16px",
        width: 350,
        height: 350,
        bgcolor: "#161616",
        transition: "all 0.3s ease-in-out",
        cursor: "pointer",
        "&:hover": {
          transform: "translateY(-5px)",
          boxShadow: `0 4px 20px 0 ${theme.palette.primary.main}20`,
        },
      }}
    >
      <Box sx={{ position: "absolute", top: 16, left: 16, zIndex: 2 }}>
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", color: "text.primary" }}
        >
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          position: "absolute",
          inset: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          style={{ width: "100%", height: "100%" }}
        >
          {React.cloneElement(svgContent, {
            style: {
              transform: isHovered
                ? "scale(1.5) translate(-16.67%, -16.67%)"
                : "scale(1) translate(0, 0)",
              transition: "transform 300ms ease-in-out",
            },
            children: React.Children.map(svgContent.props.children, (child) =>
              React.cloneElement(child, {
                style: {
                  transition: "all 300ms ease-in-out",
                  r:
                    child.props.r === "0"
                      ? isHovered
                        ? "4"
                        : "0"
                      : child.props.r,
                  width:
                    child.props.width === "0"
                      ? isHovered
                        ? child.props.x === "75"
                          ? child.props.y === "67"
                            ? "25"
                            : "18"
                          : child.props.y === "72"
                            ? "20"
                            : "15"
                        : "0"
                      : child.props.width,
                  strokeWidth:
                    child.props.strokeWidth === "0"
                      ? isHovered
                        ? "1"
                        : "0"
                      : child.props.strokeWidth,
                  fillOpacity:
                    child.props.fillOpacity === "0"
                      ? isHovered
                        ? (Math.random() * 0.3 + 0.5).toFixed(2)
                        : "0"
                      : child.props.fillOpacity,
                },
              })
            ),
          })}
        </svg>
      </Box>
      <Box
        sx={{
          position: "absolute",
          insetX: 0,
          bottom: 0,
          p: 2,
          bgcolor: "rgba(26, 26, 26, 0.8)",
          backdropFilter: "blur(4px)",
          transition: "all 0.3s ease-in-out",
          transform: isHovered ? "translateY(0)" : "translateY(100%)",
        }}
      >
        <Typography variant="h6" sx={{ color: "text.secondary" }}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default FeatureCard;