import React from 'react';
import { Box } from '@mui/material';
import { motion, useScroll, useTransform } from 'framer-motion';

const ParallaxBackground = ({ children }) => {
  const { scrollY } = useScroll();
  const y = useTransform(scrollY, [0, 500], [0, -50]); // Reduced parallax effect

  return (
    <Box sx={{ position: 'relative', overflow: 'hidden' }}> // Removed minHeight
      <motion.div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
          y,
        }}
      />
      <Box sx={{ position: 'relative', zIndex: 1 }}>
        {children}
      </Box>
    </Box>
  );
};

export default ParallaxBackground;