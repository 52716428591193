import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, IconButton, Fade, useTheme, alpha } from '@mui/material';
import { X, Lightbulb } from 'lucide-react';

const InterviewTips = () => {
    const [open, setOpen] = useState(true);
    const theme = useTheme();

    useEffect(() => {
        const timer = setTimeout(() => {
            setOpen(true);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    const tips = [
        "Understand the problem before seeking an approach.",
        "Clarify doubts and state any necessary assumptions.",
        "Discuss and validate your approach before coding.",
        "Proof-read and dry-run your code before submitting.",
    ];

    return (
        <Fade in={open}>
            <Paper
                elevation={3}
                sx={{
                    position: 'fixed',
                    bottom: { xs: 84, sm: 84, md: 84 }, // Adjusted to avoid overlap with bottom bar
                    right: { xs: 10, sm: 20, md: 20 },
                    width: { xs: 'calc(100% - 20px)', sm: 425, md: 425 },
                    maxWidth: 425,
                    p: 2,
                    bgcolor: theme.palette.background.paper,
                    borderRadius: 0.5,
                    boxShadow: `0 2px 10px #111`,
                    zIndex: 1300, // Ensure it's above other elements
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Lightbulb size={18} color={theme.palette.primary.light} style={{ marginRight: '8px' }} />
                        <Typography variant="h6" color="light" sx={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
                            Life Saving Interview Tips
                        </Typography>
                    </Box>
                    <IconButton onClick={handleClose} size="small" sx={{ color: theme.palette.text.secondary }}>
                        <X size={18} />
                    </IconButton>
                </Box>
                <Box component="ul" sx={{ pl: 2, m: 0 }}>
                    {tips.map((tip, index) => (
                        <Typography
                            key={index}
                            component="li"
                            variant="body2"
                            sx={{
                                mb: 1,
                                fontSize: '0.75rem',
                                color: alpha(theme.palette.text.primary, 0.8),
                            }}
                        >
                            {tip}
                        </Typography>
                    ))}
                </Box>
            </Paper>
        </Fade>
    );
};

export default InterviewTips;