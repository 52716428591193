import React, { useEffect, useMemo } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import LandingPage from "./components/LandingPage";
import InterviewSetupPage from "./components/InterviewSetupPage";
import InterviewPage from "./components/InterviewPage";
import FeedbackPage from "./components/FeedbackPage";
import AboutUsPage from "./components/AboutUsPage";
import InviteOnlyPage from "./components/InviteOnlyPage";
import DashboardPage from "./components/DashboardPage";
import UserSettingsPage from "./components/UserSettingsPage";
import AuthCallback from "./components/AuthCallback";
import { useAuth } from "./contexts/AuthContext";
import useLogging from "./hooks/useLogging";
import PrivacyPolicyPage from "./components/PrivacyPolicy";
import EnterpriseGetStartedPage from "./components/EnterpriseGetStartedPage";
import EarlyTesterForm from "./components/EarlyTesterForm";

const PrivateRoute = React.memo(({ children }) => {
  const { user, loading, authCallbackInProgress } = useAuth();
  const logger = useLogging("PrivateRoute");

  logger.log("In PrivateRoute, User:", user);

  if (loading || authCallbackInProgress) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return user ? children : <Navigate to="/" />;
});

const ErrorBoundary = React.memo(({ children }) => {
  const [hasError, setHasError] = React.useState(false);
  const logger = useLogging("ErrorBoundary");

  React.useEffect(() => {
    const errorHandler = (error) => {
      logger.error("Caught error:", error);
      setHasError(true);
    };

    window.addEventListener('error', errorHandler);
    return () => window.removeEventListener('error', errorHandler);
  }, [logger]);

  if (hasError) {
    return <div>Something went wrong.</div>;
  }

  return children;
});

function App() {
  const logger = useLogging("App");
  const { user, loading, authCallbackInProgress, checkUser } = useAuth();
  const { clearUserData } = useAuth();

  useEffect(() => {
    checkUser();
  }, [checkUser]);

  logger.log("App rendered", { user, loading, authCallbackInProgress });

  const content = useMemo(() => {
    if (loading || authCallbackInProgress) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      );
    }

    return (
      <Box sx={{ minHeight: "100vh", bgcolor: "background.default", color: "text.primary" }}>
        <ErrorBoundary>
          <Routes>
            <Route path="/" element={user ? <Navigate to="/dashboard" replace /> : <LandingPage />} />
            <Route path="/auth/callback" element={<AuthCallback />} />
            <Route path="/dashboard" element={<PrivateRoute><DashboardPage /></PrivateRoute>} />
            <Route path="/settings" element={<ErrorBoundary><PrivateRoute><UserSettingsPage /></PrivateRoute></ErrorBoundary>} />
            <Route path="/setup" element={<ErrorBoundary><PrivateRoute><InterviewSetupPage /></PrivateRoute></ErrorBoundary>} />
            <Route path="/interview" element={<ErrorBoundary><PrivateRoute><InterviewPage /></PrivateRoute></ErrorBoundary>} />
            <Route path="/feedback" element={<ErrorBoundary><PrivateRoute><FeedbackPage /></PrivateRoute></ErrorBoundary>} />
            <Route path="/about" element={<ErrorBoundary><AboutUsPage /></ErrorBoundary>} />
            <Route path="/invite-only" element={<ErrorBoundary><InviteOnlyPage /></ErrorBoundary>} />
            <Route path="/privacy" element={<ErrorBoundary><PrivacyPolicyPage /></ErrorBoundary>} />
            <Route path="/enterprise/get-started" element={<ErrorBoundary><EnterpriseGetStartedPage /></ErrorBoundary>} />
            <Route path="/early-tester" element={<ErrorBoundary><EarlyTesterForm /></ErrorBoundary>} />
          </Routes>
        </ErrorBoundary>
      </Box>
    );
  }, [loading, authCallbackInProgress, user]);

  useEffect(() => {
    const handleUnauthorized = () => {
      clearUserData();
      window.location.href = '/';
    };

    window.addEventListener('UNAUTHORIZED', handleUnauthorized);

    return () => {
      window.removeEventListener('UNAUTHORIZED', handleUnauthorized);
    };
  }, [clearUserData]);

  return content;
}

export default React.memo(App);
