import axios from "axios";
import { API_BASE_URL } from "../utils/constants";
import logger from "./logger";

const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Dispatch an event that can be caught by a React component
      window.dispatchEvent(new CustomEvent('UNAUTHORIZED'));
    }
    return Promise.reject(error);
  }
);

export const getDashboardData = async () => {
  try {
    logger.log("Fetching dashboard data");
    const response = await api.get('/v1/user/dashboard');
    logger.log(`Dashboard data fetched successfully: ${JSON.stringify(response.data)}`);
    return response.data;
  } catch (error) {
    logger.error('Error fetching dashboard data:', error);
    throw error;
  }
};

export const createInterviewSession = async (data) => {
  try {
    const response = await api.post("/v1/interview/create-session", data);
    return response;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response;
    }
    throw error;
  }
};

export const getInterviewDetails = async (sessionId) => {
  logger.log(`Fetching interview details for session ID: ${sessionId}`);
  try {
    const response = await api.get(`/v1/interview/details/${sessionId}`);
    logger.log(`Interview details fetched successfully for session ID: ${sessionId}`);
    return response;
  } catch (error) {
    logger.error(`Error fetching interview details for session ID: ${sessionId}`, error);
    throw error;
  }
};

export const startInterview = async (sessionId) => {
  logger.log(`Starting interview for session ID: ${sessionId}`);
  try {
    const response = await api.post("/v1/interview/start", { sessionId });
    logger.log(`Interview started successfully for session ID: ${sessionId}`);
    return response;
  } catch (error) {
    logger.error(`Error starting interview for session ID: ${sessionId}`, error);
    throw error;
  }
};

export const sendMessage = async (sessionId, message, code, timeRemaining, onChunk) => {
  try {
    const response = await fetch(`${API_BASE_URL}/v1/interview/interact`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        'Accept': 'text/event-stream',
      },
      body: JSON.stringify({
        sessionId,
        message,
        code,
        timeRemaining
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    while (true) {
      const { value, done } = await reader.read();
      if (done) break;
      
      const chunk = decoder.decode(value);
      const lines = chunk.split('\n\n').filter(line => line.trim());
      
      for (const line of lines) {
        try {
          const match = line.match(/^data: (.+)$/m);
          if (match) {
            const jsonData = JSON.parse(match[1]);
            logger.log('Received chunk:', jsonData);
            // Instead of collecting results, immediately process each chunk
            await onChunk(jsonData);
          }
        } catch (e) {
          logger.error('Error parsing SSE line:', line, e);
        }
      }
    }
  } catch (error) {
    logger.error('Error in sendMessage:', error);
    throw error;
  }
};

export const endInterview = (sessionId) =>
  api.post("/v1/interview/end", { sessionId });

export const getInterviewFeedback = (sessionId) =>
  api.post("/v1/interview/feedback", { sessionId });

export const submitUserFeedback = (sessionId, rating, comment) =>
  api.post("/v1/user-feedback/submit", { sessionId, rating, comment });

export const submitContactForm = async (formData) => {
  try {
    const response = await api.post('/v1/contact/submit', formData);
    return response.data;
  } catch (error) {
    logger.error('Error submitting contact form:', error);
    throw error;
  }
};

export const submitEarlyTesterForm = async (formData) => {
  try {
    const response = await api.post('/v1/early-tester/submit', formData);
    return response.data;
  } catch (error) {
    logger.error('Error submitting early tester form:', error);
    throw error;
  }
};

export const clearAuthToken = () => {
  delete api.defaults.headers.common['Authorization'];
  localStorage.removeItem('authToken');
};

export const submitEnterpriseForm = async (formData) => {
  try {
    logger.log("Submitting enterprise form");
    const response = await api.post('/v1/enterprise/submit-form', formData);
    logger.log(`Enterprise form submitted successfully: ${JSON.stringify(response.data)}`);
    return response.data;
  } catch (error) {
    logger.error('Error submitting enterprise form:', error);
    throw error;
  }
};

export const subscribeToUpdates = async (email) => {
  try {
    logger.log("Subscribing to updates");
    const response = await api.post('/v1/enterprise/subscribe', { email });
    logger.log(`Subscribed to updates successfully: ${JSON.stringify(response.data)}`);
    return response.data;
  } catch (error) {
    logger.error('Error subscribing to updates:', error);
    throw error;
  }
};

export default api;
