import { format } from "date-fns";

const isDevelopment = process.env.REACT_APP_ENV === 'development';

const logger = {
  log: (message, ...args) => {
    if (isDevelopment) {
      console.log(
        `[${format(new Date(), "yyyy-MM-dd HH:mm:ss")}] ${message}`,
        ...args
      );
    }
  },
  error: (message, ...args) => {
    if (isDevelopment) {
      console.error(
        `[${format(new Date(), "yyyy-MM-dd HH:mm:ss")}] ERROR: ${message}`,
        ...args
      );
    }
  },
  warn: (message, ...args) => {
    if (isDevelopment) {
      console.warn(
        `[${format(new Date(), "yyyy-MM-dd HH:mm:ss")}] WARN: ${message}`,
        ...args
      );
    }
  },
};

export default logger;
