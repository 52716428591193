import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getInterviewDetails, startInterview, sendMessage, endInterview } from '../services/api';
import useLogging from './useLogging';
import { parseISO, differenceInSeconds } from "date-fns";

const useInterviewLogic = (sessionId) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const logger = useLogging("InterviewLogic");

    const [messages, setMessages] = useState([]);
    const [code, setCode] = useState("// Start coding here...");
    const [lastAnalyzedCode, setLastAnalyzedCode] = useState("");
    const [inputMessage, setInputMessage] = useState("");
    const [sessionDetails, setSessionDetails] = useState(null);
    const [timeRemaining, setTimeRemaining] = useState(null);
    const [isTyping, setIsTyping] = useState(false);
    const [isAnalyzingCode, setIsAnalyzingCode] = useState(false);
    const streamingMessageRef = useRef('');
    const [currentStatus, setCurrentStatus] = useState(null);
    const [isEndDialogOpen, setIsEndDialogOpen] = useState(false);
    const [isTimeExpired, setIsTimeExpired] = useState(false);
    const [isInterviewEnded, setIsInterviewEnded] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isCodeAttached, setIsCodeAttached] = useState(true);
    const [isCodeModified, setIsCodeModified] = useState(false);

    const handleTimeExpired = useCallback(async () => {
        setIsTimeExpired(true);
        setIsEndDialogOpen(true);
    }, [sessionId, logger]);

    useEffect(() => {
        const fetchDetailsAndStart = async () => {
            try {
                if (!sessionId) {
                    logger.warn("No session ID provided, redirecting to dashboard");
                    navigate("/dashboard");
                    return;
                }

                const details = await getInterviewDetails(sessionId);
                logger.log("Fetched interview details", details);
                setSessionDetails(details.data);

                const endTime = parseISO(details.data.endTime);
                const now = new Date();

                const remainingSeconds = Math.max(0, differenceInSeconds(endTime, now));
                logger.log("Remaining seconds:", remainingSeconds);

                setTimeRemaining(remainingSeconds);

                startInterviewSession(sessionId);
            } catch (error) {
                logger.error("Error fetching interview details or starting interview:", error);
                navigate("/dashboard");
            }
        };

        fetchDetailsAndStart();
    }, [sessionId, navigate]);

    useEffect(() => {
        if (timeRemaining === null) return;

        const timer = setInterval(() => {
            const now = new Date();
            const endTime = sessionDetails?.endTime ? parseISO(sessionDetails.endTime) : null;
            if (!endTime) return;

            const remaining = Math.max(0, differenceInSeconds(endTime, now));
            
            if (remaining !== timeRemaining) {
                setTimeRemaining(remaining);
                
                if (remaining <= 0) {
                    clearInterval(timer);
                    handleTimeExpired();
                }
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [timeRemaining, sessionDetails, handleTimeExpired]);

    const startInterviewSession = async (sessionId) => {
        try {
            const response = await startInterview(sessionId);
            logger.log("Interview started: ", response.data);
            addMessage({ id: Date.now(), text: response.data, sender: "Byte" });
        } catch (error) {
            logger.error("Error starting interview:", error);
            addMessage({
                id: Date.now(),
                text: "Error starting interview. Please try again.",
                sender: "System",
            });
        }
    };

    const addMessage = (newMessage) => {
        setMessages(prev => [...prev, { ...newMessage, isNew: true }]);
        setTimeout(() => {
            setMessages(prev => prev.map(msg =>
                msg.id === newMessage.id ? { ...msg, isNew: false } : msg
            ));
        }, 50);
    };

    const processMessageStream = useCallback(async (responseLines, initialMessage = null) => {
        let byteMessageAdded = false;

        logger.log("Processing message stream");
        setErrorMessage(null);

        for (const line of responseLines) {
            await new Promise(resolve => setTimeout(resolve, 1));

            try {
                const data = line;

                // Handle status updates first
                if (data.status === "analyzing_code" || 
                    data.status === "processing_response") {
                    setCurrentStatus(data.status);
                    logger.log("Status updated to:", data.status);
                    continue; // Skip the rest of the loop for status updates
                }

                // Handle message streaming
                if (data.status === "streaming") {
                    setCurrentStatus(null); // Clear status when streaming starts
                    if (!byteMessageAdded) {
                        setMessages(prevMessages => [
                            ...prevMessages,
                            { 
                                id: Date.now(), 
                                sender: 'Byte', 
                                text: initialMessage || data.chunk, 
                                isStreaming: true 
                            }
                        ]);
                        byteMessageAdded = true;
                        logger.log("Streaming message received:", data.chunk);
                    } else {
                        setMessages(prevMessages => {
                            const lastMessage = prevMessages[prevMessages.length - 1];
                            return [
                                ...prevMessages.slice(0, -1),
                                { ...lastMessage, text: lastMessage.text + data.chunk }
                            ];
                        });
                    }
                } else if (data.status === "completed") {
                    setCurrentStatus(null);
                    setMessages(prevMessages => {
                        const lastMessage = prevMessages[prevMessages.length - 1];
                        return [
                            ...prevMessages.slice(0, -1),
                            { ...lastMessage, text: data.response, isStreaming: false }
                        ];
                    });
                } else if (data.status === "ended") {
                    setCurrentStatus("ended");
                    addMessage({
                        id: Date.now(),
                        text: "Thanks for answering the question. That's all for now. I'll be ending the interview now.",
                        sender: "Byte",
                    });
                    addMessage({
                        id: Date.now(),
                        text: "The interview has been completed. Thank you for your participation.",
                        sender: "System",
                    });
                    setTimeout(() => {
                        setIsEndDialogOpen(true);
                    }, 1000);
                    logger.log("Interview ended messages sent.");
                }
            } catch (parseError) {
                logger.error('Error processing message:', parseError, 'Line:', line);
            }
        }
    }, [setMessages, setCurrentStatus, setIsEndDialogOpen, addMessage, logger]);

    const handleSendMessage = useCallback(async (isRetry = false) => {
        if (!isRetry && !inputMessage.trim()) return;
        
        setErrorMessage(null);
        const shouldAttachCode = isCodeAttached && isCodeModified;
        
        let messageToSend;
        if (isRetry) {
            const lastUserMessage = [...messages].reverse().find(msg => msg.sender === 'You');
            messageToSend = lastUserMessage ? lastUserMessage.text : '';
        } else {
            messageToSend = inputMessage;
        }
        
        if (!isRetry) {
            const newMessage = { 
                id: Date.now(), 
                sender: 'You', 
                text: messageToSend,
                codeAttached: shouldAttachCode
            };
            setMessages(prevMessages => [...prevMessages, newMessage]);
            setInputMessage('');
        }

        setIsTyping(true);
        const isCodeChanged = shouldAttachCode && code !== lastAnalyzedCode;
        setIsAnalyzingCode(isCodeChanged);

        setCurrentStatus(isCodeChanged ? "analyzing_code" : "thinking");

        try {
            setMessages(prev => prev.filter(msg => msg.id !== 'error'));
            
            await sendMessage(
                sessionId,
                messageToSend,
                shouldAttachCode ? code : null,
                timeRemaining,
                async (chunk) => {
                    await processMessageStream([chunk], "");
                }
            );
        } catch (error) {
            logger.error('Error sending message:', error);
            if (error.response && error.response.data && error.response.data.message) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('An error occurred while processing your message.');
            }
        } finally {
            setIsTyping(false);
            setIsAnalyzingCode(false);
            setCurrentStatus(null);
            if (isCodeChanged) {
                setLastAnalyzedCode(code);
            }
        }
    }, [inputMessage, code, sessionId, timeRemaining, lastAnalyzedCode, processMessageStream, isCodeAttached, isCodeModified, messages]);

    const handleEndInterview = useCallback(() => {
        setIsEndDialogOpen(true);
    }, []);

    const confirmEndInterview = useCallback(async () => {
        try {
            setCurrentStatus("ending_interview");
            const response = await endInterview(sessionId);
            logger.log("Interview ended", response.data);
            setIsInterviewEnded(true);
            navigate(`/feedback?sessionId=${sessionId}`);
        } catch (error) {
            logger.error("Error ending interview:", error);
            setErrorMessage("Failed to end the interview. Please try again.");
            throw error; // Re-throw the error so the dialog can handle it
        } finally {
            setCurrentStatus(null);
        }
    }, [sessionId, logger, navigate]);

    const cancelEndInterview = useCallback(() => {
        setIsEndDialogOpen(false);
    }, []);

    const handleAnalyzeCode = useCallback(async () => {
        if (!code.trim()) return;
        setIsTyping(true);
        setIsAnalyzingCode(true);

        const userMessage = "Can you analyze this code and provide feedback?";
        addMessage({ 
            id: Date.now(), 
            text: userMessage, 
            sender: "You",
            codeAttached: true  
        });

        setCurrentStatus("analyzing_code");

        try {
            await sendMessage(
                sessionId,
                userMessage,
                code,
                timeRemaining,
                async (chunk) => {
                    await processMessageStream([chunk], "");
                }
            );
        } catch (error) {
            logger.error('Error analyzing code:', error);
            addMessage({
                id: Date.now(),
                text: "Error analyzing code. Please try again.",
                sender: "System",
            });
        } finally {
            setIsTyping(false);
            setIsAnalyzingCode(false);
            setCurrentStatus(null);
            setLastAnalyzedCode(code);
        }
    }, [code, sessionId, timeRemaining, addMessage, processMessageStream]);

    const toggleCodeAttachment = useCallback(() => {
        setIsCodeAttached(prev => !prev);
    }, []);

    const handleCodeChange = useCallback((newCode) => {
        setCode(newCode);
        // Check if the code has been meaningfully modified
        setIsCodeModified(newCode.trim() !== "" && newCode.trim() !== "// Start coding here...");
    }, []);

    return useMemo(() => ({
        messages,
        inputMessage,
        setInputMessage,
        isTyping,
        isAnalyzingCode,
        timeRemaining,
        code,
        setCode: handleCodeChange,
        handleSendMessage,
        handleEndInterview,
        handleAnalyzeCode,
        sessionId,
        currentStatus,
        isEndDialogOpen,
        isInterviewEnded,
        confirmEndInterview,
        cancelEndInterview,
        isTimeExpired,
        errorMessage,
        setErrorMessage,
        handleTimeExpired,
        isCodeAttached,
        toggleCodeAttachment,
        isCodeModified,
    }), [
        messages,
        inputMessage,
        isTyping,
        isAnalyzingCode,
        timeRemaining,
        code,
        handleCodeChange,
        handleSendMessage,
        handleEndInterview,
        handleAnalyzeCode,
        sessionId,
        currentStatus,
        isEndDialogOpen,
        isInterviewEnded,
        confirmEndInterview,
        cancelEndInterview,
        isTimeExpired,
        errorMessage,
        handleTimeExpired,
        isCodeAttached,
        toggleCodeAttachment,
        isCodeModified,
    ]);
};

export default useInterviewLogic;
