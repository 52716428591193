import React from 'react';
import { Typography, Button, Box, useTheme } from '@mui/material';

const CallToAction = ({ handleStart }) => {
  const theme = useTheme();

  return (
    <Box sx={{ textAlign: "center", mt: 20 }}>
      <Typography
        variant="h2"
        component="h2"
        sx={{
          fontSize: "3rem",
          fontWeight: "bold",
          mb: 4,
          letterSpacing: "-0.03em",
        }}
      >
        Ready to ace your next coding interview?
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={handleStart}
        sx={{
          px: 6,
          py: 2,
          background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
          "&:hover": {
            transform: "translateY(-2px)",
            boxShadow: `0 6px 20px 0 ${theme.palette.primary.main}60`,
            bgcolor: theme.palette.primary.dark,
          },
          fontSize: "1.25rem",
          fontWeight: "bold",
          borderRadius: "12px",
          textTransform: "none",
          boxShadow: `0 4px 14px 0 ${theme.palette.primary.main}40`,
          mt: 5,
        }}
      >
        GET STARTED
      </Button>
    </Box>
  );
};

export default CallToAction;