export function stringToColor(string) {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF;
    // Ensure the color component is in the brighter range (128-255)
    const brightValue = Math.floor(((value + 255) / 2) * 0.7 + 128 * 0.3);
    color += ('00' + brightValue.toString(16)).substr(-2);
  }
  return lightenColor(color);
}

// New function to create a lighter version of a color
export function lightenColor(color, factor = 0.3) {
  const hex = color.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  const lightenChannel = (channel) => Math.round(channel + (255 - channel) * factor);

  return `#${[r, g, b].map(lightenChannel).map(c => c.toString(16).padStart(2, '0')).join('')}`;
}