import React, { useEffect } from 'react';
import { Box, useTheme, Button, Select, MenuItem } from "@mui/material";
import Editor from "@monaco-editor/react";
import useLogging from "../../../hooks/useLogging";
import { Code } from "lucide-react";

const CodingArea = ({ code, setCode, onCodePresenceChange, programmingLanguage, onLanguageChange, onAnalyzeCode }) => {
  const logger = useLogging("CodingArea");
  const theme = useTheme();

  const handleEditorChange = (value, event) => {
    logger.log("Editor content changed");
    setCode(value);
  };

  const handleEditorDidMount = (editor, monaco) => {
    logger.log("Editor mounted successfully");
  };

  const handleEditorWillMount = (monaco) => {
    logger.log("Editor will mount");
  };

  const handleEditorValidation = (markers) => {
    markers.forEach((marker) => logger.log('Validation issue:', marker.message));
  };

  useEffect(() => {
    onCodePresenceChange(code.trim() !== "" && code.trim() !== "// Start coding here...");
  }, [code, onCodePresenceChange]);

  return (
    <Box sx={{
      width: "60%",
      display: "flex",
      flexDirection: "column",
      bgcolor: theme.palette.background.default,
      borderLeft: `1px solid ${theme.palette.divider}`,
      position: 'relative',
    }}>
      <Editor
        height="calc(100% - 64px)" // Adjust for bottom bar height
        language={programmingLanguage}
        defaultValue="// Start coding here..."
        value={code}
        onChange={handleEditorChange}
        onMount={handleEditorDidMount}
        beforeMount={handleEditorWillMount}
        onValidate={handleEditorValidation}
        theme="vs-dark"
        options={{
          padding: { top: 25, bottom: 25 },
          minimap: { enabled: false },
          fontSize: 16,
          quickSuggestions: false,
          wordWrap: "on",
          lineNumbers: "on",
          folding: false,
          scrollBeyondLastLine: false,
          renderLineHighlight: 'all',
          renderIndentGuides: true,
          cursorBlinking: 'smooth',
          cursorStyle: 'line',
          smoothScrolling: true,
        }}
        loading={<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', color: theme.palette.text.secondary }}>Loading editor...</Box>}
      />
      <Box sx={{
        height: '64px',
        borderTop: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: 2,
        bgcolor: theme.palette.background.paper,
      }}>
        <Select
          value={programmingLanguage}
          onChange={(e) => onLanguageChange(e.target.value)}
          sx={{
            height: 40,
            minWidth: 120,
            color: theme.palette.primary.main,
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.primary.main,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.primary.light,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.primary.main,
            },
          }}
        >
          <MenuItem value="python">Python</MenuItem>
          <MenuItem value="javascript">JavaScript</MenuItem>
          <MenuItem value="java">Java</MenuItem>
          <MenuItem value="cpp">C++</MenuItem>
          <MenuItem value="c">C</MenuItem>
        </Select>
        <Button
          variant="contained"
          color="primary"
          onClick={onAnalyzeCode}
          startIcon={<Code size={18} />}
          sx={{
            height: 40,
            textTransform: 'none',
            fontWeight: 'bold',
            px: 2,
            borderRadius: '4px',
            bgcolor: theme.palette.primary.main,
            color: theme.palette.background.default,
            '&:hover': {
              bgcolor: theme.palette.primary.dark,
            },
          }}
        >
          Submit For Analysis
        </Button>
      </Box>
    </Box>
  );
};

export default CodingArea;
