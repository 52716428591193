import React, { useState, useCallback, useRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Typography, useTheme, alpha, CircularProgress, LinearProgress } from "@mui/material";
import useLogging from '../../../hooks/useLogging';

const EndInterviewDialog = ({ open, onClose, onConfirm, isTimeExpired }) => {
    const theme = useTheme();
    const logger = useLogging("EndInterviewDialog");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [progress, setProgress] = useState(100);
    const timerStartedRef = useRef(false);

    const handleConfirm = useCallback(async () => {
        setIsSubmitting(true);
        try {
            await onConfirm();
            // If onConfirm is successful, the dialog will be closed by the parent component
        } catch (error) {
            logger.error("Failed to end interview:", error);
            setIsSubmitting(false);
            // Optionally, you can show an error message here
        }
    }, [onConfirm, logger]);

    React.useEffect(() => {
        let timeoutId;
        let intervalId;

        if (open && isTimeExpired && !timerStartedRef.current) {
            timerStartedRef.current = true;
            setProgress(100);
            
            intervalId = setInterval(() => {
                setProgress((prevProgress) => {
                    const newProgress = prevProgress - 1;
                    return Math.max(newProgress, 0);
                });
            }, 100);

            timeoutId = setTimeout(() => {
                handleConfirm();
            }, 10000);
        }

        // Reset the ref when the dialog closes
        if (!open) {
            timerStartedRef.current = false;
        }

        return () => {
            if (!open) {
                clearTimeout(timeoutId);
                clearInterval(intervalId);
                setProgress(100);
            }
        };
    }, [open, isTimeExpired, handleConfirm]);

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick' && !isSubmitting) {
                    onClose();
                }
            }}
            disableEscapeKeyDown
            PaperProps={{
                style: {
                    backgroundColor: alpha(theme.palette.background.paper, 0.9),
                    borderRadius: '8px',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.5)',
                    backdropFilter: 'blur(8px)',
                }
            }}
        >
            <DialogTitle sx={{ 
                borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                padding: theme.spacing(3, 4),
            }}>
                <Typography variant="h6" component="span" sx={{ 
                    fontWeight: 'bold',
                    background: theme.palette.primary.main,
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                }}>
                    {isTimeExpired ? "Time's Up!" : "End Interview"}
                </Typography>
            </DialogTitle>
            <DialogContent sx={{ padding: theme.spacing(4) }}>
                <DialogContentText sx={{ 
                    paddingTop: theme.spacing(4),
                    color: alpha(theme.palette.text.primary, 0.8),
                    fontSize: '0.95rem',
                }}>
                    {isTimeExpired 
                        ? "The interview time is up. Submitting your final solution..."
                        : "Are you sure you want to end the interview and submit your final solution?"}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ 
                padding: theme.spacing(0, 4, 4),
                position: 'relative',
            }}>
                {!isTimeExpired && (
                    <Button 
                        onClick={onClose}
                        disabled={isSubmitting}
                        sx={{
                            padding: theme.spacing(1, 3),
                            '&:hover': {
                                backgroundColor: alpha(theme.palette.text.secondary, 0.1),
                            }
                        }}
                    >
                        Cancel
                    </Button>
                )}
                <Button 
                    onClick={handleConfirm}
                    variant="contained"
                    disabled={isSubmitting}
                    sx={{
                        backgroundColor: alpha(theme.palette.primary.main, 0.1),
                        color: theme.palette.primary.main,
                        padding: theme.spacing(1, 3),
                        width: '300px',
                        height: '50px',
                        '&:hover': {
                            backgroundColor: alpha(theme.palette.primary.main, 0.2),
                        },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        overflow: 'hidden',
                    }}
                >
                    {isSubmitting ? (
                        <CircularProgress size={24} color="inherit" />
                    ) : (
                        "Submit and End Interview"
                    )}
                    {isTimeExpired && !isSubmitting && (
                        <LinearProgress
                            variant="determinate"
                            value={progress}
                            sx={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                right: 0,
                                height: 3,
                                backgroundColor: alpha(theme.palette.primary.main, 0.1),
                                '& .MuiLinearProgress-bar': {
                                    backgroundColor: theme.palette.primary.main,
                                }
                            }}
                        />
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default React.memo(EndInterviewDialog);
