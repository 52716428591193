export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "https://api.dobr.ai";

// New additions
const isDevelopment = process.env.REACT_APP_ENV === 'development';

export const APP_CONFIG = {
  cognito: {
    region: 'ap-southeast-2',
    userPoolId: 'ap-southeast-2_BIw14AvpK',
    userPoolWebClientId: 'nu2ab6eeccce2p72d53h9cbmd',
    domain: 'auth.dobr.ai',
  },
  app: {
    baseUrl: isDevelopment ? 'http://localhost:3000' : 'https://www.dobr.ai',
  },
};

export const getRedirectUrl = (path = '/dashboard') => {
  return `${APP_CONFIG.app.baseUrl}${path}`;
};

export const getLoginUrl = () => {
  const redirectUri = encodeURIComponent(getRedirectUrl('/dashboard'));
  return `https://${APP_CONFIG.cognito.domain}/login?response_type=code&client_id=${APP_CONFIG.cognito.userPoolWebClientId}&redirect_uri=${redirectUri}`;
};

export const getSignupUrl = () => {
    const redirectUri = encodeURIComponent(getRedirectUrl('/dashboard'));
    return `https://${APP_CONFIG.cognito.domain}/signup?response_type=code&client_id=${APP_CONFIG.cognito.userPoolWebClientId}&redirect_uri=${redirectUri}`;
};
