import React from 'react';
import { Box, useTheme } from '@mui/material';

const AnimatedBackground = () => {
  const theme = useTheme();

  return (
    <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
          overflow: "hidden",
        }}
      >
        <svg
          style={{ width: "100%", height: "100%" }}
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop
                offset="0%"
                stopColor={theme.palette.primary.dark}
                stopOpacity="0.05"
              />
              <stop
                offset="100%"
                stopColor={theme.palette.background.default}
                stopOpacity="0"
              />
            </linearGradient>
          </defs>
          <rect width="100%" height="100%" fill="url(#grad1)">
            <animate
              attributeName="x"
              from="-100%"
              to="100%"
              dur="20s"
              repeatCount="indefinite"
            />
          </rect>

          <circle
            cx="80%"
            cy="42%"
            fill={theme.palette.primary.main}
            opacity="0.02"
          >
            <animate
              attributeName="r"
              values="60;90;60"
              dur="15s"
              repeatCount="indefinite"
              calcMode="spline"
              keySplines="0.5 0 0.5 1; 0.5 0 0.5 1"
            />
          </circle>

          <path
            d="M0,50 Q50,100 100,50 T200,50"
            stroke={theme.palette.primary.main}
            strokeWidth="0.5"
            fill="none"
            opacity="0.4"
            transform="translate(100, 200)"
          >
            <animate
              attributeName="d"
              values="M0,50 Q50,100 100,50 T200,50;M0,50 Q50,0 100,50 T200,50;M0,50 Q50,100 100,50 T200,50"
              dur="20s"
              repeatCount="indefinite"
            />
          </path>

          <path
            d="M0,50 Q50,100 100,50 T200,50"
            stroke={theme.palette.primary.main}
            strokeWidth="0.5"
            fill="none"
            opacity="0.5"
            transform="translate(1300, 800)"
          >
            <animate
              attributeName="d"
              values="M0,50 Q50,100 100,50 T200,50;M0,50 Q50,0 100,50 T200,50;M0,50 Q50,100 100,50 T200,50"
              dur="20s"
              repeatCount="indefinite"
            />
          </path>

          <circle
            cx="20%"
            cy="70%"
            r="50"
            fill={theme.palette.secondary.main}
            opacity="0.05"
          >
            <animate
              attributeName="cy"
              values="70%;75%;70%"
              dur="10s"
              repeatCount="indefinite"
              calcMode="spline"
              keySplines="0.5 0 0.5 1; 0.5 0 0.5 1"
            />
          </circle>

          <path
            d="M0,0 Q25,-25 50,0 T100,0"
            stroke={theme.palette.secondary.light}
            strokeWidth="0.5"
            fill="none"
            opacity="0.3"
            transform="translate(0, 100)"
          >
            <animate
              attributeName="d"
              values="M0,0 Q25,-25 50,0 T100,0;M0,0 Q25,25 50,0 T100,0;M0,0 Q25,-25 50,0 T100,0"
              dur="18s"
              repeatCount="indefinite"
            />
          </path>

          <circle
            cx="90%"
            cy="85%"
            r="30"
            fill={theme.palette.primary.main}
            opacity="0.04"
          >
            <animate
              attributeName="r"
              values="30;40;30"
              dur="8s"
              repeatCount="indefinite"
              calcMode="spline"
              keySplines="0.5 0 0.5 1; 0.5 0 0.5 1"
            />
          </circle>
        </svg>
      </Box>
  );
};

export default AnimatedBackground;