import React from 'react';
import { Box, Container, Typography, Link, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const Footer = ({ sx = {}, linkSx = {} }) => {
  const theme = useTheme();

  return (
    <Box 
      component="footer" 
      sx={{ 
        py: 4, 
        bgcolor: 'background.paper',
        borderTop: `1px solid ${theme.palette.divider}`,
        position: 'relative',
        zIndex: 2, 
        ...sx, // Spread custom styles
      }}
    >
      <Container maxWidth="lg">
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: 2, sm: 0 },
          }}
        >
          <Typography variant="body2" color="text.secondary">
            © {new Date().getFullYear()} Dobr Digital Pvt. Ltd. All rights reserved.
          </Typography>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Link 
              component={RouterLink} 
              to="/about" 
              color="inherit" 
              sx={{ 
                textDecoration: 'none',
                '&:hover': { color: theme.palette.primary.main },
                transition: 'color 0.3s ease',
                ...linkSx, // Spread custom link styles
              }}
            >
              About Us
            </Link>
            <Link 
              component={RouterLink} 
              to="/privacy" 
              color="inherit"
              sx={{ 
                textDecoration: 'none',
                '&:hover': { color: theme.palette.primary.main },
                transition: 'color 0.3s ease',
                ...linkSx, // Spread custom link styles
              }}
            >
              Privacy Policy
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;