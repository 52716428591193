import React from 'react';
import { Typography, Container, Box } from '@mui/material';

function UserSettingsPage() {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          User Settings
        </Typography>
        <Typography variant="body1">
          This page will contain user settings and preferences.
        </Typography>
      </Box>
    </Container>
  );
}

export default UserSettingsPage;