import React, { useMemo, useEffect, useRef, useCallback } from 'react';
import { Box, Typography, Divider, TextField, IconButton, CircularProgress, useTheme } from "@mui/material";
import { Send, Paperclip, X, RotateCw } from "lucide-react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkGfm from "remark-gfm";
import rehypeRaw from 'rehype-raw';

const MarkdownRenderer = React.memo(({ content }) => {
    return (
        <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            components={{
                code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || "");
                    return !inline && match ? (
                        <SyntaxHighlighter
                            style={atomDark}
                            language={match[1]}
                            PreTag="div"
                            {...props}
                        >
                            {String(children).replace(/\n$/, "")}
                        </SyntaxHighlighter>
                    ) : (
                        <code className={className} {...props}>
                            {children}
                        </code>
                    );
                },
            }}
        >
            {content}
        </ReactMarkdown>
    );
});

const StatusUpdate = React.memo(({ status, theme }) => (
  <Box sx={{ display: "flex", alignItems: "center", mb: 2, height: 24 }}>
    <Typography
      variant="body2"
      sx={{
        fontWeight: "bold",
        color: theme.palette.secondary.main,
        mr: 1,
      }}
    >
      {status === "analyzing_code" ? "Analyzing code" :
       status === "processing_response" ? "Byte is thinking" :
       "Processing"}
    </Typography>
    <CircularProgress size={20} color="secondary" />
  </Box>
));

const Message = React.memo(({ message, theme, onRetry }) => {
  const isError = message.sender === 'System' && message.text.toLowerCase().includes('error');

  const handleRetry = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    console.log('Retry button clicked');
    if (onRetry) {
      console.log('Calling onRetry with true');
      onRetry(true);
    } else {
      console.log('onRetry is not defined');
    }
  };

  if (message.status) {
    return <StatusUpdate status={message.status} theme={theme} />;
  }

  return (
    <Box
      sx={{
        mb: 3,
        opacity: message.isNew ? 0 : 1,
        transform: message.isNew ? 'translateY(20px)' : 'translateY(0)',
        transition: 'opacity 300ms, transform 300ms',
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: "bold",
            color: isError 
              ? `${theme.palette.error.main}99` 
              : message.sender === "You" 
                ? theme.palette.primary.main 
                : theme.palette.secondary.main,
            mr: 2,
            fontSize: "0.8rem",
          }}
        >
          {message.sender}
        </Typography>
        {message.codeAttached && (
          <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
            <Paperclip size={14} />
            <Typography variant="caption" sx={{ ml: 0.5 }}>Code attached</Typography>
          </Box>
        )}
        <Divider sx={{ flex: 1 }} />
      </Box>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.text.primary,
        fontSize: "0.9rem",
        backgroundColor: isError ? `${theme.palette.error.dark}10` : 'transparent',
        padding: isError ? theme.spacing(1) : 0,
        borderRadius: isError ? theme.spacing(0.5) : 0,
        border: isError ? `1px solid ${theme.palette.error.main}20` : 'none',
        "& code": {
          backgroundColor: theme.palette.background.default,
          padding: "2px 4px",
          borderRadius: 1,
          fontSize: "0.8rem",
        },
        "& pre": {
          backgroundColor: theme.palette.background.default,
          padding: theme.spacing(1),
          borderRadius: 1,
          overflowX: "auto",
          fontSize: "0.8rem",
        },
        "& a": {
          color: theme.palette.primary.main,
        },
        "& table": {
          borderCollapse: "collapse",
          fontSize: "0.8rem",
          "& th, & td": {
            border: `1px solid ${theme.palette.divider}`,
            padding: theme.spacing(0.5, 1),
          },
        },
      }}>
        <Box sx={{ flex: 1 }}>
          <MarkdownRenderer content={message.text} />
        </Box>
        {isError && onRetry && (
          <IconButton 
            onClick={handleRetry}
            size="small"
            sx={{ 
              ml: 1,
              color: `${theme.palette.error.main}99`,
              '&:hover': {
                backgroundColor: `${theme.palette.error.main}15`,
              }
            }}
          >
            <RotateCw size={20} />
          </IconButton>
        )}
      </Box>
    </Box>
  );
});

const ChatArea = React.memo(({ 
  messages, 
  inputMessage, 
  setInputMessage, 
  handleSendMessage, 
  isTyping, 
  currentStatus, 
  messagesEndRef,
  errorMessage,
  isInterviewEnded,
  isCodeAttached,
  isCodeModified,
  toggleCodeAttachment
}) => {
  const theme = useTheme();
  const containerRef = useRef(null);

  const handleInputChange = useMemo(() => (e) => setInputMessage(e.target.value), [setInputMessage]);
  const handleKeyPress = useMemo(() => (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  }, [handleSendMessage]);

  useEffect(() => {
    const scrollToBottom = () => {
      if (containerRef.current) {
        const { scrollHeight, clientHeight } = containerRef.current;
        containerRef.current.scrollTop = scrollHeight - clientHeight;
      }
    };

    scrollToBottom();
  }, [messages]);

  // Create a memoized retry handler specifically for error messages
  const handleRetry = useCallback(() => {
    console.log('handleRetry called');
    handleSendMessage(true);  // Explicitly pass true for retry
  }, [handleSendMessage]);

  // Create a memoized handler for normal sends
  const handleSendClick = useCallback(() => {
    console.log('handleSendClick called');
    handleSendMessage(); 
  }, [handleSendMessage]);


  if (isInterviewEnded) {
    return null; // or some message indicating the interview has ended
  }

  return (
    <Box sx={{
      width: "40%", 
      borderRight: `1px solid ${theme.palette.divider}`, 
      display: "flex", 
      flexDirection: "column", 
      bgcolor: "rgba(15, 15, 15, 0.8)"
    }}>
      <Box 
        ref={containerRef}
        sx={{ 
          flexGrow: 1, 
          overflow: "auto", 
          p: 2,
          scrollBehavior: "auto",
        }}
      >
        {messages.map((message) => (
          <Message 
            key={message.id} 
            message={message} 
            theme={theme}
            onRetry={handleRetry}  // Pass the retry handler instead
          />
        ))}
        {currentStatus && <StatusUpdate status={currentStatus} theme={theme} />}
        {errorMessage && (
          <Message
            message={{
              id: 'error',
              sender: 'System',
              text: errorMessage,
            }}
            theme={theme}
            onRetry={handleRetry}  // Pass the retry handler here too
          />
        )}
        <div ref={messagesEndRef} />
      </Box>
      <Box sx={{
        bgcolor: "rgba(26, 26, 26, 0.8)",
        borderTop: `1px solid ${theme.palette.divider}`,
      }}>
        <TextField
          fullWidth
          multiline
          minRows={1}
          maxRows={4}
          variant="outlined"
          placeholder="Type your message..."
          value={inputMessage}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          InputProps={{
            endAdornment: (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {isCodeAttached && isCodeModified && (
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      mr: 1, 
                      bgcolor: 'rgba(100, 255, 218, 0.1)', 
                      borderRadius: '4px',
                      padding: '2px 6px',
                    }}
                  >
                    <Paperclip size={16} />
                    <Typography variant="caption" sx={{ ml: 0.5 }}>Code</Typography>
                    <IconButton size="small" onClick={toggleCodeAttachment} sx={{ ml: 0.5 }}>
                      <X size={14} />
                    </IconButton>
                  </Box>
                )}
                <IconButton
                  size="small"
                  onClick={handleSendClick}
                  sx={{
                    color: theme.palette.primary.main,
                    "&:hover": {
                      bgcolor: "rgba(100, 255, 218, 0.1)",
                    },
                  }}
                >
                  <Send size={20} />
                </IconButton>
              </Box>
            ),
            style: { fontSize: "0.9rem" },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                border: 'none',
              },
              borderRadius: 0,
            }
          }}
        />
      </Box>
    </Box>
  );
});

export default ChatArea;
