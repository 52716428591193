import React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  marginBottom: theme.spacing(4),
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.grey[200], // Darker grey for better visibility
  borderRadius: theme.shape.borderRadius,
  fontWeight: 800,
  fontSize: '1rem', // Increased font size
  textTransform: 'none', // Prevents all-caps text
  boxShadow: 'none', // Removes button shadow
  padding: theme.spacing(1, 3), // Adjust padding as needed
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.grey[400], // Darker on hover
  },
  '&:active': {
    backgroundColor: 'transparent',
    color: theme.palette.grey[900], // Even darker when clicked
  },
}));

const CustomAlertDialog = ({ open, onClose, title, message }) => {
  const theme = useTheme();

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <StyledDialogTitle id="alert-dialog-title">
        <Typography variant="h6" component="span">
          {title}
        </Typography>
      </StyledDialogTitle>
      <StyledDialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledButton 
          onClick={onClose} 
          variant="contained" 
          color="primary" 
          autoFocus
        >
          OK
        </StyledButton>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default CustomAlertDialog;