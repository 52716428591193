import React from 'react';
import { Button, useTheme } from '@mui/material';
import { ChevronUp } from 'lucide-react';

const ScrollToTopButton = ({ showScrollTop, scrollToTop }) => {
  const theme = useTheme();

  if (!showScrollTop) return null;

  return (
    <Button
      onClick={scrollToTop}
      sx={{
        position: "fixed",
        bottom: 32,
        right: 32,
        minWidth: 0,
        width: 56,
        height: 56,
        borderRadius: "50%",
        boxShadow: `0 2px 10px 0 ${theme.palette.primary.main}40`,
        "&:hover": {
          boxShadow: `0 4px 20px 0 ${theme.palette.primary.main}60`,
        },
        zIndex: 3,
      }}
      variant="contained"
      color="primary"
    >
      <ChevronUp size={32} />
    </Button>
  );
};

export default ScrollToTopButton;