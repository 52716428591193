import React from 'react';
import { Box, useTheme } from "@mui/material";
import { motion } from "framer-motion";

const AnimatedBackground = () => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 0,
                overflow: "hidden",
            }}
        >
            <svg
                style={{ width: "100%", height: "100%" }}
                xmlns="http://www.w3.org/2000/svg"
            >
                <defs>
                    <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
                        <stop
                            offset="0%"
                            stopColor={theme.palette.primary.dark}
                            stopOpacity="0.03"
                        />
                        <stop
                            offset="100%"
                            stopColor={theme.palette.background.default}
                            stopOpacity="0"
                        />
                    </linearGradient>
                </defs>

                {/* Floating circles */}
                <motion.circle
                    cx="80%"
                    cy="20%"
                    r="50"
                    fill={theme.palette.primary.main}
                    opacity="0.05"
                    animate={{
                        scale: [1, 1.2, 1],
                        x: ["0%", "5%", "0%"],
                        y: ["0%", "5%", "0%"],
                    }}
                    transition={{
                        duration: 15,
                        repeat: Infinity,
                        repeatType: "reverse",
                    }}
                />

                <motion.circle
                    cx="20%"
                    cy="70%"
                    r="30"
                    fill={theme.palette.secondary.main}
                    opacity="0.03"
                    animate={{
                        scale: [1, 1.1, 1],
                        x: ["0%", "-3%", "0%"],
                        y: ["0%", "-3%", "0%"],
                    }}
                    transition={{
                        duration: 12,
                        repeat: Infinity,
                        repeatType: "reverse",
                    }}
                />

                {/* Pulsating dot */}
                <motion.circle
                    cx="90%"
                    cy="85%"
                    r="5"
                    fill={theme.palette.secondary.main}
                    animate={{
                        opacity: [0.2, 0.5, 0.2],
                        scale: [1, 1.2, 1],
                    }}
                    transition={{
                        duration: 3,
                        repeat: Infinity,
                    }}
                />

                {/* Rotating square */}
                <motion.rect
                    x="10%"
                    y="30%"
                    width="20"
                    height="20"
                    fill={theme.palette.warning.main}
                    opacity="0.05"
                    animate={{
                        rotate: 360,
                    }}
                    transition={{
                        duration: 10,
                        repeat: Infinity,
                        ease: "linear",
                    }}
                />

                {/* Wavy paths */}
                <path
                    d="M0,50 Q50,100 100,50 T200,50"
                    stroke={theme.palette.secondary.main}
                    strokeWidth="0.5"
                    fill="none"
                    opacity="0.6"
                    transform="translate(100, 200)"
                >
                    <animate
                        attributeName="d"
                        values="M0,50 Q50,100 100,50 T200,50;M0,50 Q50,0 100,50 T200,50;M0,50 Q50,100 100,50 T200,50"
                        dur="20s"
                        repeatCount="indefinite"
                    />
                </path>

                <path
                    d="M100,0 Q150,50 200,0 T300,0"
                    stroke={theme.palette.primary.main}
                    strokeWidth="0.5"
                    fill="none"
                    opacity="0.4"
                    transform="translate(900, 500)"
                >
                    <animate
                        attributeName="d"
                        values="M100,0 Q150,50 200,0 T300,0;M100,0 Q150,-50 200,0 T300,0;M100,0 Q150,50 200,0 T300,0"
                        dur="25s"
                        repeatCount="indefinite"
                    />
                </path>

                {/* Moving dot along a path */}
                <path
                    id="motionPath"
                    d="M50,50 Q150,150 250,50 T450,50"
                    stroke="none"
                    fill="none"
                />
                <motion.circle
                    r="3"
                    fill={theme.palette.secondary.light}
                    opacity="0.2"
                >
                    <motion.animateMotion
                        path="M50,50 Q150,150 250,50 T450,50"
                        dur="15s"
                        repeatCount="indefinite"
                    />
                </motion.circle>
            </svg>
        </Box>
    );
};

export default AnimatedBackground;