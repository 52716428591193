import React, { useState, useCallback, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
    AppBar,
    Toolbar,
    Container,
    Box,
    Typography,
    useTheme,
    Button,
    Menu,
    MenuItem,
    IconButton,
    styled,
    useMediaQuery
} from "@mui/material";
import { alpha } from '@mui/material/styles';  // Add this import
import { useAuth } from "../../../contexts/AuthContext";
import useLogging from "../../../hooks/useLogging";
import { stringToColor } from "../../../utils/colorUtils";
import LogoutIcon from '@mui/icons-material/Logout';
import { ListItemIcon } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

const Header = React.memo(() => {
    const { user, login, signup, logout } = useAuth();
    const logger = useLogging("Header");
    const navigate = useNavigate();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState(null);

    const handleLogoClick = useCallback(() => {
        navigate("/");
    }, [navigate]);

    const handleMenuOpen = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleMenuClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleLogin = useCallback(() => {
        logger.log('Login button clicked');
        login();
    }, [logger, login]);

    const handleSignup = useCallback(() => {
        logger.log('Signup button clicked');
        signup();
    }, [logger, signup]);

    const handleLogout = useCallback(() => {
        logout();
        handleMenuClose();
    }, [logout, handleMenuClose]);

    const handleNavigateToSettings = useCallback(() => {
        handleMenuClose();
        navigate("/settings");
    }, [handleMenuClose, navigate]);

    const userGradient = useMemo(() => {
        if (!user?.username) return 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)';
        const color1 = stringToColor(user.username);
        const color2 = stringToColor(user.username.split('').reverse().join(''));
        return `linear-gradient(45deg, ${color1} 30%, ${color2} 90%)`;
    }, [user]);

    const userMenu = useMemo(() => (
        <>
            <IconButton 
                onClick={handleMenuOpen} 
                size="small" 
                sx={{ 
                    ml: 2,
                    padding: 0,
                    boxShadow: (theme) => `0 0 10px #999`,
                }}
            >
                <Box
                    sx={{
                        width: 32,
                        height: 32,
                        borderRadius: '50%',
                        background: userGradient,
                    }}
                />
            </IconButton>
            <StyledMenu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </StyledMenu>
        </>
    ), [user, anchorEl, handleMenuOpen, handleMenuClose, handleNavigateToSettings, handleLogout, userGradient]);

    const handleMobileMenuOpen = useCallback((event) => {
        setMobileMenuAnchorEl(event.currentTarget);
    }, []);

    const handleMobileMenuClose = useCallback(() => {
        setMobileMenuAnchorEl(null);
    }, []);

    const handleLoginAndClose = useCallback(() => {
        handleLogin();
        handleMobileMenuClose();
    }, [handleLogin]);

    const handleSignupAndClose = useCallback(() => {
        handleSignup();
        handleMobileMenuClose();
    }, [handleSignup]);

    const loginButtons = useMemo(() => (
        <>
            {isMobile ? (
                <>
                    <IconButton
                        color="inherit"
                        onClick={handleMobileMenuOpen}
                        edge="end"
                        sx={{ ml: 1, mr: 0 }} // Add some left margin
                    >
                        <MenuIcon />
                    </IconButton>
                    <StyledMenu
                        anchorEl={mobileMenuAnchorEl}
                        open={Boolean(mobileMenuAnchorEl)}
                        onClose={handleMobileMenuClose}
                    >
                        <MenuItem onClick={handleSignupAndClose}>
                            <ListItemIcon>
                                <PersonAddIcon fontSize="small" />
                            </ListItemIcon>
                            Get Started
                        </MenuItem>
                        <MenuItem onClick={handleLoginAndClose}>
                            <ListItemIcon>
                                <LoginIcon fontSize="small" />
                            </ListItemIcon>
                            Login
                        </MenuItem>
                    </StyledMenu>
                </>
            ) : (
                <>
                    <Button 
                        component={Link} 
                        to="/enterprise/get-started" 
                        color="inherit" 
                        sx={{ mr: 2 }}
                    >
                        For Companies
                    </Button>
                    <Button onClick={handleLogin} color="inherit" sx={{ mr: 2 }}>
                        Login
                    </Button>
                    <Button 
                        onClick={handleSignup} 
                        variant="outlined" 
                        color="inherit"
                    >
                        Get Started
                    </Button>
                </>
            )}
        </>
    ), [handleLogin, handleSignup, isMobile, mobileMenuAnchorEl, handleMobileMenuOpen, handleMobileMenuClose, handleLoginAndClose, handleSignupAndClose]);

    logger.log("Header rendered. User state:", user);

    const content = useMemo(() => (
        <AppBar position="static" color="transparent" elevation={0}>
            <Container maxWidth={false} sx={{ px: { xs: 2, sm: 3, md: 4 } }}>
                <Toolbar
                    disableGutters
                    sx={{ 
                        justifyContent: "space-between", 
                        py: { xs: 2, sm: 1 }  // Increased top padding for xs screens
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer"
                        }}
                        onClick={handleLogoClick}
                    >
                        <Box
                            sx={{
                                width: 40,
                                height: 40,
                                borderRadius: "50%",
                                border: `2px solid ${theme.palette.primary.main}`,
                                mr: 2,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        ></Box>
                        <Typography
                            variant="h5"
                            component="div"
                            sx={{
                                fontWeight: "bold",
                                letterSpacing: 1,
                                display: "flex",
                                alignItems: "center",
                                color: 'text.primary',
                            }}
                        >
                            Dobr.AI
                            <Typography
                                component="span"
                                sx={{
                                    ml: 2,
                                    background: theme.palette.secondary.main,
                                    color: theme.palette.background.default,
                                    padding: "2px 6px",
                                    borderRadius: "4px",
                                    fontSize: "0.7rem",
                                    fontWeight: "bold",
                                    opacity: 0.6,
                                    textTransform: "uppercase",
                                    boxShadow: `0 2px 4px ${theme.palette.primary.main}40`,
                                }}
                            >
                                Beta
                            </Typography>
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        {user ? userMenu : loginButtons}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    ), [user, theme, handleLogoClick, userMenu, loginButtons]);

    return content;
});

export default Header;