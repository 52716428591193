import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import useLogging from "../hooks/useLogging";

function AuthCallback() {
  const { handleAuthCallback } = useAuth();
  const location = useLocation();
  const logger = useLogging("AuthCallback");

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code');

    if (code) {
      logger.log('Handling auth callback');
      handleAuthCallback(code);
    }
  }, [location, handleAuthCallback, logger]);

  return <div>Processing login...</div>;
}

export default AuthCallback;