import React, { useState } from "react";
import { createInterviewSession } from "../services/api";
import { useNavigate } from "react-router-dom";
import {
  Chip,
  Box,
  Container,
  Typography,
  Button,
  useTheme,
  ToggleButton,
  ToggleButtonGroup,
  CircularProgress,
  Fade,
} from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { motion } from "framer-motion";
import useLogging from "../hooks/useLogging";
import Header from "./subcomponents/common/Header";
import AnimatedBackground from "./subcomponents/common/AnimatedBackground";
import CustomAlertDialog from './subcomponents/common/CustomAlertDialog';
import Avatar from '@mui/material/Avatar';
import { alpha } from "@mui/material";

const tags = [
  "Arrays",
  "Strings",
  "Linked List",
  "Stack",
  "Tree",
  "Graph",
  "Hashing",
  "Recursion",
  "Greedy",
  "Dynamic Programming",
  "Design",
];

const companyTags = [
  { name: "Google", logo: "https://www.google.com/favicon.ico" },
  { name: "Meta", logo: "https://static.xx.fbcdn.net/rsrc.php/v3/yV/r/8PtnTFGuUVu.png" },
  { name: "Amazon", logo: "https://www.amazon.com/favicon.ico" },
  { name: "Microsoft", logo: "https://www.microsoft.com/favicon.ico" },
  { name: "Apple", logo: "https://www.apple.com/favicon.ico" },
  { name: "Netflix", logo: "https://assets.nflxext.com/us/ffe/siteui/common/icons/nficon2016.ico" },
  { name: "Uber", logo: "https://www.uber.com/_static/bafeeed0a39bd111.png" },
  { name: "Airbnb", logo: "https://a0.muscache.com/airbnb/static/logotype_favicon-21cc8e6c6a2cca43f061d2dcabdf6e58.ico" },
  { name: "LinkedIn", logo: "https://static.licdn.com/sc/h/akt4ae504epesldzj74dzred8" },
];

const difficulties = ["Easy", "Medium", "Hard"];

const InterviewSetupPage = () => {
  const navigate = useNavigate();
  const logger = useLogging("InterviewSetupPage");
  const theme = useTheme();

  const [difficulty, setDifficulty] = useState("Easy");
  const [generalError, setGeneralError] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedCompanyTags, setSelectedCompanyTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showMobileAlert, setShowMobileAlert] = useState(false);

  const handleTagToggle = (tag) => {
    setSelectedTags((prev) =>
      prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag]
    );
  };

  const handleCompanyTagToggle = (tag) => {
    setSelectedCompanyTags((prev) =>
      prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag]
    );
  };

  const getTimeLimitForDifficulty = (difficulty) => {
    switch (difficulty) {
      case "Easy":
        return 30 * 60; // 30 minutes in seconds
      case "Medium":
        return 45 * 60; // 45 minutes in seconds
      case "Hard":
        return 55 * 60; // 55 minutes in seconds
      default:
        return 45 * 60; // Default to 30 minutes
    }
  };

  const handleStartInterview = async () => {
    if (isSmallScreen()) {
      setShowMobileAlert(true);
      return;
    }

    setIsLoading(true);
    try {
      const timeLimit = getTimeLimitForDifficulty(difficulty);
      const response = await createInterviewSession({
        difficulty,
        tags: [...selectedTags, ...selectedCompanyTags],
        timeLimit,
      });

      if (response.data.sessionId) {
        navigate(`/interview?sessionId=${response.data.sessionId}`);
      } else if (response.data.status === "overloaded") {
        setGeneralError(response.data.message);
      } else {
        setGeneralError("Failed to create interview session");
      }
    } catch (error) {
      logger.error("Error starting interview:", error);
      setGeneralError("An error occurred while starting the interview");
    } finally {
      setIsLoading(false);
    }
  };

  const isSmallScreen = () => {
    return window.innerWidth < 768; // Consider screens smaller than 768px as small
  };

  const handleCloseAlert = () => {
    setShowMobileAlert(false);
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        bgcolor: "background.default",
        color: "text.primary",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <AnimatedBackground />
      <Header />

      <Container
        maxWidth="sm"
        sx={{
          mt: 8,
          mb: 4,
          position: "relative",
          zIndex: 1
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Typography
            variant="h2"
            component="h1"
            sx={{
              mb: 6,
              textAlign: "center",
              fontWeight: "bold",
              background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Choose Your Focus
          </Typography>
        </motion.div>
        <Box
          component="form"
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            handleStartInterview();
          }}
          sx={{ mt: 1 }}
        >
          <Box sx={{ mt: 3, mb: 2 }}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Filter Tags (Optional)
            </Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {tags.map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  onClick={() => handleTagToggle(tag)}
                  color={selectedTags.includes(tag) ? "primary" : "default"}
                  sx={{
                    transition: "all 0.3s ease",
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: 2,
                    }
                  }}
                />
              ))}
            </Box>
          </Box>
          <Box sx={{ mt: 3, mb: 2 }}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Company Tags (Optional)
            </Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {companyTags.map((tag) => (
                <Chip
                  key={tag.name}
                  avatar={<Avatar src={tag.logo} alt={tag.name} sx={{ width: 24, height: 24 }} />}
                  label={tag.name}
                  onClick={() => handleCompanyTagToggle(tag.name)}
                  color={selectedCompanyTags.includes(tag.name) ? "secondary" : "default"}
                  sx={{
                    transition: "all 0.3s ease",
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: 2,
                    },
                    '& .MuiChip-avatar': {
                      marginLeft: '4px',
                      marginRight: '-4px',
                    },
                  }}
                />
              ))}
            </Box>
          </Box>
          <Box sx={{ mt: 3, mb: 2 }}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Difficulty
            </Typography>
            <ToggleButtonGroup
              value={difficulty}
              exclusive
              onChange={(e, newDifficulty) => setDifficulty(newDifficulty)}
              aria-label="difficulty"
              sx={{ width: "100%", justifyContent: "space-between" }}
            >
              {difficulties.map((diff) => (
                <ToggleButton
                  key={diff}
                  value={diff}
                  sx={{
                    flex: 1,
                    transition: 'all 0.3s ease',
                    "&.Mui-selected": {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                      "&:hover": {
                        backgroundColor: theme.palette.primary.dark,
                      },
                    },
                  }}
                >
                  {diff}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>
          {generalError && (
            <Fade in={!!generalError}>
              <Box
                sx={{
                  mt: 3,
                  p: 2,
                  borderRadius: 1,
                  backgroundColor: alpha(theme.palette.error.light, 0.1),
                  border: `1px solid ${alpha(theme.palette.error.main, 0.3)}`,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  transition: 'all 0.3s ease',
                }}
              >
                <ErrorOutlineIcon
                  sx={{
                    color: theme.palette.error.main,
                    fontSize: 20,
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.error.main,
                    fontWeight: 500,
                    flex: 1,
                  }}
                >
                  {generalError}
                </Typography>
              </Box>
            </Fade>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleStartInterview}
            disabled={isLoading}
            sx={{
              mt: 3,
              mb: 2,
              py: 2,
              fontSize: "1.1rem",
              fontWeight: "bold",
              position: "relative",
              overflow: "hidden",
              transition: "all 0.3s ease",
              background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              "&:hover": {
                transform: "translateY(-2px)",
                boxShadow: 4,
              },
            }}
          >
            <Fade in={!isLoading}>
              <span>Start Interview</span>
            </Fade>
            <Fade in={isLoading}>
              <CircularProgress
                size={24}
                sx={{
                  color: theme.palette.primary.contrastText,
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            </Fade>
          </Button>
        </Box>
      </Container>
      <CustomAlertDialog
        open={showMobileAlert}
        onClose={handleCloseAlert}
        title="Device Not Supported"
        message="Interviews are not supported on mobile devices due to screen size limitations. Please use a desktop or laptop computer."
      />
    </Box>
  );
};

export default InterviewSetupPage;
